import { PermissionCheckerService, FeatureCheckerService } from 'abp-ng2-module';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { Menu, MenuService } from '@delon/theme';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class AppNavigationService {
    webSiteRootAddress = AppConsts.webSiteRootAddress ?? '/';
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _featureCheckerService: FeatureCheckerService,
        private _appSessionService: AppSessionService,
        private _localizationService: AppLocalizationService,
        private _ngAlainMenuService: MenuService,
    ) {}

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            // new AppMenuItem('Tổng quan', 'Pages.Administration.Host.Dashboard', 'dashboard', '/app/admin/hostDashboard'),
            new AppMenuItem('Giới thiệu chung', 'Pages.GioiThieuChung', 'home', '/app/gioi-thieu-chung/bai-viet'),
            new AppMenuItem('Tổng quan', 'Pages.Tenant.Dashboard', 'dashboard', '/app/main/dashboard'),

            new AppMenuItem('Quản lý dữ liệu', 'Pages.QuanLyDuLieu', 'bars', '/app/quan-ly-du-lieu/nhap-du-lieu'),

            new AppMenuItem('Tính toán chỉ số', 'Pages.TinhToanChiSo', 'bars', '/app/tinh-toan-chi-so/form-tinh-toan'),

            new AppMenuItem('AI hỗ trợ', 'Pages.AiHoTro', 'bars', '', [
                new AppMenuItem('Biểu đồ thống kê', 'Pages.AiHoTro.BieuDoThongKe', 'area-chart', '/app/ai-ho-tro/bieu-do-thong-ke'),
                new AppMenuItem('Dự đoán có bệnh lý  NAFLD', 'Pages.AiHoTro.DuDoanBenhLyNAFLD', 'bars', '/app/ai-ho-tro/du-doan-benh-ly-nafld'),
                new AppMenuItem('Dự đoán chuyển hóa HCC', 'Pages.AiHoTro.DuDoanTienLuong', 'bars', '/app/ai-ho-tro/du-doan-tien-luong'),
            ]),

            // new AppMenuItem('Hệ thống', 'Pages.HeThong', 'tool', '', [
            //     //new AppMenuItem('Cài đặt', 'Pages.HeThong.CaiDat', 'setting', '/app/he-thong/cai-dat'),
            //     new AppMenuItem('Tài khoản người dùng', 'Pages.HeThong.QuanLyTaiKhoan', 'user', '/app/he-thong/tai-khoan-nguoi-dung'),
            //     new AppMenuItem('Hướng dẫn sử dụng', 'Pages.HeThong.TaiLieuHuongDan', 'book', '/app/he-thong/tai-lieu-huong-dan'),
            //     // new AppMenuItem('Thiết lập chữ ký số', 'Pages.HeThong.ThietLapChuKySo', 'setting', '/app/he-thong/thiet-lap-chu-ky-so'),
            //     //new AppMenuItem('Thiết lập chung', 'Pages.HeThong.ThietLapChung', 'setting', '/app/he-thong/thiet-lap-chung'),
            //     //new AppMenuItem('Thiết lập domain', 'Pages.HeThong.DomainRoute', 'setting', '/app/he-thong/thiet-lap-domain'),
            //     //new AppMenuItem('Danh sách gửi mail', 'Pages.HeThong.DanhSachGuiMail', 'book', '/app/he-thong/danh-sach-gui-mail'),
            // ]),

            new AppMenuItem('Administration', 'Pages.Administration', 'appstore', '', [
                // new AppMenuItem('Tỉnh / thành phố', 'Pages.DanhMuc.Tinh', 'environment', '/app/danh-muc/tinh'),
                // new AppMenuItem('Quận / huyện', 'Pages.DanhMuc.Huyen', 'environment', '/app/danh-muc/huyen'),
                // new AppMenuItem('Phường / xã', 'Pages.DanhMuc.Xa', 'environment', '/app/danh-muc/xa'),
                new AppMenuItem('Quản lý bài viết', 'Pages.DanhMuc.QuanLyBaiViet', 'bars', '/app/danh-muc/quan-ly-bai-viet'),
                new AppMenuItem('Cấu hình ngưỡng đánh giá', 'Pages.Administration.CauHinhNguongDanhGia', 'bars', '/app/admin/cau-hinh-nguong-danh-gia'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'safety', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'user', '/app/admin/users'),
                new AppMenuItem('Nhật ký hệ thống', 'Pages.Administration.AuditLogs', 'book', '/app/admin/auditLogs'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'setting', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'setting', '/app/admin/tenantSettings'),
                //new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'team', '/app/admin/organization-units'),
                //new AppMenuItem('Languages', 'Pages.Administration.Languages', 'global', '/app/admin/languages'),
                //new AppMenuItem('LanguageTexts', 'Pages.Administration.LanguageTexts', 'global', '/app/admin/languagetexts'),
                //new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'lock', '/app/admin/maintenance'),
                // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
            ]),

            // new AppMenuItem('Tenants', 'Pages.Tenants', 'bars', '/app/admin/tenants'),
            // new AppMenuItem('Editions', 'Pages.Editions', 'shopping', '/app/admin/editions'),
            // new AppMenuItem('Hướng dẫn sử dụng', 'Pages.HuongDanSuDung', 'check-circle', '/app/he-thong/xem-huong-dan-su-dung'),
        ]);
    }

    mapToNgAlainMenu() {
        let menu = this.getMenu();
        let ngAlainRootMenu = <Menu>{
            text: this._localizationService.l(menu.name),
            group: false,
            hideInBreadcrumb: true,
            children: [],
        };
        this.generateNgAlainMenus(ngAlainRootMenu.children, menu.items);

        let ngAlainMenus = [ngAlainRootMenu];
        this._ngAlainMenuService.add(ngAlainMenus);
    }

    generateNgAlainMenus(ngAlainMenus: Menu[], appMenuItems: AppMenuItem[]) {
        appMenuItems.forEach((item) => {
            let ngAlainMenu: Menu;

            ngAlainMenu = {
                text: this._localizationService.l(item.name),
                link: item.route,
                icon: {
                    type: 'icon',
                    value: item.icon,
                },
                hide: !this.showMenuItem(item),
            };

            if (item.items && item.items.length > 0) {
                ngAlainMenu.children = [];
                this.generateNgAlainMenus(ngAlainMenu.children, item.items);
            }

            ngAlainMenus.push(ngAlainMenu);
        });
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            } else if (!subMenuItem.permissionName) {
                return true;
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        if (hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
